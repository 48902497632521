<template>
    <div
        class="ion"
        :class="ionClass"
        :title="iconTitle"
        name="icon-arrow-forward">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 48 38">
            <path
                d="M29.21 36.883l-2.381-2.404L40.43 20.724.004 20.733v-3.455l40.426-.009L26.825 3.52l2.379-2.406 17.69 17.881L29.21 36.883z"/>
        </svg>
    </div>
</template>

<script>
import IoniconsMixin from './mixin'

export default {
    name: "icon-arrow-forward",
    mixins: [
        IoniconsMixin
    ],
    data() {
        let iconTitle = this.title ? this.title : ""
        return {
            iconTitle: iconTitle
        }
    }
}
</script>
