<template>
    <div class="tile" :class="`tile-${item.id}`" @mouseover="showHint=true" @mouseleave="showHint=false">
        <figure v-if="item.image" class="tile__image" :class="`tile__image--${item.format}`">
            <img :src="item.image"/>
            <div v-if="formatVideo" class="tile__image-icon">
                <icon-play-solid/>
            </div>
        </figure>
        <div class="tile__content">
            <p>{{ item.title.rendered || item.title }}</p>
        </div>
        <transition name="slide-dropdown">
            <div v-if="item.hint" v-show="showHint" class="tile__hint">
                <span>{{ item.hint }}</span><icon-arrow-forward/>
            </div>
        </transition>
    </div>
</template>

<script>
import IconPlaySolid from "../icons/iconPlaySolid";
import IconArrowForward from "../icons/iconArrowForward";
export default {
    name: "ItemStandard",
    components: {IconArrowForward, IconPlaySolid},
    props: {item: {type: Object, required: true}},
    data() {
        return {
            showHint: false
        }
    },
    computed: {
        formatVideo() {
            return this.item.format === "video";
        }
    }
}
</script>

<style scoped lang="scss">
</style>