<template>
    <div
        class="ion"
        :class="ionClass"
        :title="iconTitle"
        name="icon-play-solid">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <path d="M 4.5 2.4375 L 4.5 21.5625 L 19.5 12 Z M 4.5 2.4375 "/>
        </svg>
    </div>
</template>

<script>
import IoniconsMixin from './mixin'

export default {
    name: "icon-play-solid",
    mixins: [
        IoniconsMixin
    ],
    data() {
        let iconTitle = this.title ? this.title : ""
        return {
            iconTitle: iconTitle
        }
    }
}
</script>
