<template>
    <base-overlay @close="closeOverlay" :class="{'is-scrolled':isScrolled}">
        <div class="page" :class="{'is-scrolled':isScrolled}" @wheel="scrollDetect" @scroll="scrollHandler" data-wrap
             ref="wrapEl">
            <h1 class="overview__title" v-if="labels && labels.blog">
                {{ labels.blog.title }}</h1>
            <ul class="term-filter">
                <li v-if="categories && categories.length">
                    <button class="button button-bordered" :class="{isActive:!cats.length}" @click="clearCats">
                        All
                    </button>
                </li>
                <li v-for="item in categories" :key="item.id">
                    <label class="button button-bordered" :class="{isActive:item.active}">
                        <input v-if="item.id==cats[0]" type="checkbox" name="cat" :value="item.id" v-model="cats">
                        <input v-else type="radio" name="cat" :value="item.id" v-model="cats[0]">
                        {{ item.name }}
                    </label>
                </li>
            </ul>
            <div class="masonry-wrapper" v-if="categories.length" :class="{'has-overlay':hovered}"
                 ref="scrollEl">
                <vue-masonry-wall v-if="items.length" :items="items" :options="{width: 350, throttle:1000}"
                                  @append="append">
                    <template v-slot:default="{item}">
                        <transition appear name="fade-tile" mode="out-in">
                            <router-link :to="{name:routeName, params:{page:item.slug}}" :key="item.id"
                                         class="masonry-inner"
                                         :class="{'is-faded':(hoveredItem && hoveredItem!=item.id)}"
                                         :style="{transitionDelay: `${item.index*0.1}s`}"
                                         @mouseenter.native="setHoveredItem(item.id)"
                                         @mouseleave.native="setHoveredItem(0)"
                            >
                                <item-quote v-if="isFormatQuote(item)" :item="item" :key="item.id"/>
                                <item-aside v-else-if="isFormatAside(item)" :item="item" :key="item.id"/>
                                <item-standard v-else :item="item" :key="item.id"/>
                            </router-link>
                        </transition>
                    </template>
                </vue-masonry-wall>
            </div>
        </div>
    </base-overlay>
</template>

<script>
import {intersection as _intersection} from "lodash";
import VueMasonryWall from "vue-masonry-wall";
import store from "../store";
import ItemQuote from "../components/ItemQuote";
import ItemStandard from "../components/ItemStandard";
import ItemAside from "../components/ItemAside";
import c from "../router/constants";
import requestMixin from "../mixins/requestMixin";
import overlayMixin from "../mixins/overlayMixin";
import BaseOverlay from "../components/baseOverlay.vue";
//`/about/${item.slug}`

export default {
    name: "About",
    components: {BaseOverlay, ItemAside, ItemStandard, ItemQuote, VueMasonryWall},
    mixins: [requestMixin, overlayMixin],
    data() {
        return {
            page: 0,
            paged: 8,
            items: [],
            cats: [],
            hovered: false,
            hoveredItem: 0,
            isScrolled: false
        }
    },

    computed: {
        posts() {
            return store.posts.filter(post => this.postFilter(post))
        },
        categories() {
            return store.posts_categories.map(cat => ({...cat, active: this.cats.includes(cat.id)}))
        },
        labels() {
            return store.labels
        },
        routeName() {
            return c.POST
        },
        console: () => console
    },
    mounted() {
        if (!store.posts || !store.posts.length) {
            this.loadPosts()
        }

        if (!store.posts_categories || !store.posts_categories.length) {
            this.loadCategories()
        }
    },

    // created() {
    //     this.unwatch = this.$watch('posts', this.init)
    //
    // },

    watch: {
        posts: {
            handler(newPosts) {
                this.resetPage()
                this.init()
            },
            // force eager callback execution
            immediate: true
        }
    },

    methods: {
        init() {
            this.$nextTick(() => {
                if (this.posts && this.posts.length) {
                    for (let i = 0; i < this.paged; i++) {
                        if (this.posts[i]) {
                            this.items.push({...this.posts[i], ...{index: i}})
                        }
                    }
                }
            })
        },

        resetPage() {
            this.items = []
            this.page = 1
        },

        append() {
            if (this.posts.length < this.page * this.paged) {
                return;
            }

            let start = this.page * this.paged;
            this.page++;
            let end = this.page * this.paged;
            for (let i = start; i < end; i++) {
                if (this.posts[i])
                    this.items.push({...this.posts[i], ...{index: i - start}})
            }
        },

        isFormatQuote(item) {
            if (!item) return;
            return item.format && item.format === 'quote'
        },

        isFormatAside(item) {
            if (!item) return;
            return item.format && item.format === 'aside'
        },

        postFilter(post) {
            if (!this.cats.length)
                return true;

            if (_intersection(post.categories, this.cats).length)
                return true;

            return false;
        },

        onCategoryClick(id) {
            let a = this.cats.indexOf(id)
            if (a > -1) {
                this.cats = this.cats.filter(i => i !== id)
            } else {
                this.cats.push(id)
            }
        },

        clearCats() {
            this.cats = []
        },

        scrollHandler(event) {
            this.isScrolled = (event.target && event.target.scrollTop > 50)
        },

        scrollDetect(event) {
            // if (event.target.hasAttribute('data-wrap')) {
            //     this.$refs.scrollEl ? this.$refs.scrollEl.scrollTo({
            //         top: this.$refs.scrollEl.scrollTop += event.deltaY * 2.5,
            //         behavior: "smooth"
            //     }) : null;
            // }
        },

        setHoveredItem(id) {
            console.log(id)
            this.hoveredItem = id;
        }
    },

    // beforeRouteUpdate(to, from) {
    //     // console.log('from: ', from)
    // },
    //
    // beforeRouteLeave(to, from, next) {
    //     // console.log('to: ', to)
    //     next();
    // }
}
</script>

<style scoped lang="scss">
.masonry-wrapper {
    //margin-bottom: -120px;
    //padding-bottom: 100px;
}

.page,
.section {
    flex-wrap: nowrap;
    justify-content: flex-start;
    transition: padding-top 0.15s;
    padding-top: 120px;

    @media screen and (min-width: 768px) {
        padding-top: #{"max(160px, 25vh)"};
        height: 100%;
    }

    &.is-scrolled {
        @media screen and (min-width: 768px) {
            padding-top: 160px;
        }
    }
}

.page {
    padding-bottom: 100px;
}

a {
    display: block;
}

.masonry-wrapper {
    scroll-behavior: smooth;
}

.masonry-inner {
    background: #151515;

    @media (pointer: fine) {
        .masonry-wrapper.has-overlay & {
            //background: #151515;


            //& > * {
            //    opacity: 0.1;
            //    transition: opacity 0.15s;
            //}
            //
            //
            //&:hover {
            //    & > * {
            //        opacity: 1;
            //    }
            //
            //}
        }
    }
}

.masonry-wrapper::v-deep {
    .masonry-item {
        padding: 16px;
    }

    .masonry-inner {
        & > * {
            transition: opacity 0.25s ease-in-out;
        }

        //
    }

    .is-faded {
        & > * {
            opacity: 0.1;
        }

    }
}

.term-filter {
    position: sticky;
    top: 0;
    padding-bottom: 10px;
    z-index: 6;
    transition: background-color 0.2s;

    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        left: 50%;
        height: 100%;
        transform: translateX(-50%);
    }
}

.modal {
    overflow: auto;

    &::before {
        background: #151515;
        opacity: 0.5;
        pointer-events: none;
    }

    @media screen and (min-width: 768px) {
        &::before {
            height: #{"max(160px, 25vh)"};
            bottom: auto;
            z-index: 2;
            transition: all 0.15s;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: #{"max(160px, 25vh)"};
            background: #151515;
            opacity: 0.5;
            transition: all 0.15s;
        }

        //.page:before {
        //    height: calc((#{"max(160px, 25vh)"}) + 75px);
        //    bottom: auto;
        //    z-index: 6;
        //    background-color: rgba(#151515, 0.9);
        //    background-image: linear-gradient(to top, rgba(21, 21, 21, 0), rgba(0, 0, 0, 0.95) 99%);
        //}

        &.is-scrolled {
            &::before {
                opacity: 0.9;
                height: 160px;
            }

            &::after {
                top: 160px;
            }

            .term-filter {
                &::before {
                    background-color: #151515;
                    background-color: rgba(#151515, 0.9);
                }

            }
        }
    }
}
</style>