<template>
    <div class="tile-quote" :class="[`tile-${item.id}`, {'--style-bright':isBright}]">
        <p class="tile-quote__quote">{{ item.quote || item.title.rendered || item.title }}</p>
    </div>
</template>

<script>
export default {
    name: "ItemQuote",
    props: {item: {type: Object, required: true}},
    computed: {
        isBright: function (){
            return this.item.quote_style && this.item.quote_style === 'bright'
        }
    }
}
</script>

<style scoped>
</style>